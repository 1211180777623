import aboutbgd from "./../../assets/images/aboutbgd.png"
import beerWindows from "./../../assets/images/beerwindows.jpg"
import philipguana from "./../../assets/images/philipguana.jpg"
import jameswilliams from "./../../assets/images/james-williams.jpg"
import bevind from "./../../assets/images/craftbrew.jpg"
import bevworld from "./../../assets/images/chilled.jpg"
import fastcompany from "./../../assets/images/beerandbrewering.jpg"
import Header from './Header';
import Footer from './Footer';
import { useEffect } from "react"

export default function Index() {
	document.title = "BevPort - About"
	useEffect(() => {
		const script = document.getElementById('recaptcha-key');
		if (script) {
			script.remove();
		}
		const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
		if (recaptchaElems.length) {
			recaptchaElems[0].remove();
		}
	}, [])
	return (
		<>
			<div className="site-wrapper">
				<div className="main-wrapper">
					<Header />
					

					<section className="GenesisBevPort" id="GenesisBevPortStory">
						<div className="Persistence">
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<h1>BevPort Best Practices</h1>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12">
										<p className="authorintro">Welcome to BevPort! This guide is designed to help new brewers navigate our platform effectively and maximize your reach to boost sales.</p>

										<p className="authorintro">When working with a digital distributor like BevPort, it's important to understand how we differ from traditional distribution models. BevPort operates more like Amazon, providing a platform for you to promote your brand while offering retailers an easy way to place orders and receive your products.</p>
										<p className="authorintro">Our BevPort Ambassadors focus on promoting the platform's functionality and ease of use to retailers-not individual brands. While we're here to support you and ensure a profitable experience, our primary goal is to grow our buyer base and increase sales across all our brewers. You should focus on building brand awareness and driving traffic to your products through social media or a dedicated sales representative.</p>
										<p className="authorintro"> <b>BevPort Sales Tools</b></p>
										<p className="authorintro">At BevPort, your success is our priority. To help you grow your sales, we provide several tools:</p>


										<div>
											<ul>
                                                <li className="authorintro"><b>Product Profile:</b> This is your chance to shine! Use high-quality images and videos to tell your brand's story. Highlight what makes your products unique, and showcase any awards or recognitions from social media or beverage ratings. Buyers visit this section to learn more about your brand, so make it compelling!</li>
                                                <li className="authorintro"><b>Built-In Fans:</b> Reach out to buyers in Florida who have shown interest in your brand or similar products. Engaging with potential customers directly can significantly boost your visibility and sales.</li>
                                                <li className="authorintro"><b>Regional Affiliations:</b> Target specific groups or affiliations that align with your brand. For example, if you're a craft brewery in Wisconsin, connect with Green Bay Packers bars or retailers frequented by fans. These venues are perfect for showcasing a Wisconsin brew!</li>
												<li className="authorintro"><b>Retailer Sales List:</b> This is one of your most valuable resources. Access the Retailer Sales List from your dashboard, and you'll have a database of nearly 5,000 retailers across the state. Use this list to directly promote your brand and expand your business. We provide this resource to all our clients to support their growth.</li>
											</ul>
										</div>





										<p className="authorintro"> <b>Sales Team:</b>  BevPort also offers an a la carte option to hire a sales team through the portal. You can have your own representative in the market, specifically focused on promoting your brand and presenting it directly to retailers. This is an excellent tool to get your brand off the ground and into the hands of retailers. Additionally, we offer a Retail Chain service to help you get in front of the state's largest retailers. With years of experience and connections with buyers across the country, we'll ensure your brand gets the visibility it deserves. </p>


										<div>
											<ul>
												<li className="authorintro"><b>Ground-Level Sales Services:</b> BevPort now provides additional sales services to help your brand grow locally. With a sales representative in the market, they can introduce your brand to retailers, offer tastings, and take orders directly from independent retailers and restaurants.</li>
												<li className="authorintro"><b>Retail Chain Services:</b> BevPort has a team of Retail Chain experts with years of experience to help your brand get in front of all the major Retail Chain buyers throughout the state of Florida.</li>																			
											</ul>
										</div>



										
										<p className="authorintro">With all these great tools and resources we can work together to make your brand a success! </p>

										
										
									</div>
									
								</div>
							</div>
						</div>
					</section>



					<Footer />
				</div>
			</div>
		</>
	)
}