import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from 'moment';
import axios from 'axios';

import { Modal } from "react-bootstrap";
import Button from '@mui/material/Button';

import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
    headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function OrderHistory() {
    document.title = "BevPort - invoice"
    const [toggle, setToggle] = useState(window.innerWidth <= 993?true:false);
    const [submitLoader, setSubmitLoader] = useState(true);
    const [activeTab, setActiveTab] = useState("all")
    const [show, setShow] = useState(false);
    const handleClose2 = () => setShow(false);

    const collapseSidebar = () => {
        setToggle(!toggle)
    }
    
    const columns = [
        {
            name: 'Order ID',
            selector: row => row.orderID,
            cell: row => row.orderID,
            width: '127px',
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            cell: row => <span style={{ "color": "#00aeef" }}>{(row.status == 1) ? "BOL Created" : (row.status == 2) ? "Order For Pickup" : (row.status == 3) ? "Picked Up" : (row.status == 4) ? "Taken to SSD" : (row.status == 5) ? "Out for Delivery" : (row.status == 7) ? "Delivered" : ""}</span>,
            width: '110px',
            sortable: true
        },   
        {
            name: 'Brand',
            selector: row => row.brandName,
            cell: row => row.brandName,
            width: '185px',
            sortable: true,
        },
        {
            name: 'Qty. In Cases',
            selector: row => row.quantity,
            cell: row => row.quantity,
            width: '150px',
            sortable: true,
        },
        {
            name: 'Order Date',
            selector: row => (row.orderDateVal) ? moment(row.orderDateVal, 'YYYY-MM-DD HH:mm A') : 0,
            cell: row => row.orderDateVal ? moment(row.orderDateVal, 'YYYY-MM-DD hh:mm A').format('MM-DD-YYYY hh:mm A') : "-",
            width: '200px',
            sortable: true,
        },
        {
            name: 'Total Amount',
            selector: row => parseFloat(row.amount),
            cell: row => "$" + parseFloat(row.amount).toFixed(2),
            width: '150px',
            sortable: true,
        },   
        {
            name: 'Brewer Pymt Amount',
            selector: row => row.brewerPaymentAmount,
            cell: row => "$" + row.brewerPaymentAmount,
            width: '200px',
            sortable: true,
        },
        {
            name: 'Delivery Fee',
            selector: row => row.deliveryFee,
            cell: row => "$" + row.deliveryFee,
            width: '135px',
            sortable: true
        },
        {
            name: 'Bevport Net Amount',
            selector: row => row.bevportNetAmount,
            cell: row => "$" + row.bevportNetAmount,
            width: '190px',
            sortable: true
        },
        {
            name: 'Action',
            selector: row => <>
                <Link className="btn btn-primary" to={"/admin/bol-order-invoice/" + row.orderID} target='_blank' >View</Link></>,
        }

    ];



    const columnsDetails = [
     
        {
            name: 'Order ID',
            selector: row => row.orderID,
            cell: row => row.orderID,
            width: '110px',
            sortable: true
        },

        {
            name: 'Status',
            selector: row => row.status,
            cell: row => <span style={{ "color": "#00aeef" }}>{(row.status == 1) ? "BOL Created" : (row.status == 2) ? "Order For Pickup" : (row.status == 3) ? "Picked Up" : (row.status == 4) ? "Taken to SSD" : (row.status == 5) ? "Out for Delivery" : (row.status == 7) ? "Delivered" : ""}</span>,
            width: '110px',
            sortable: true
        },   

        {
            name: 'Brand',
            selector: row => row.productName,
            cell: row => row.productName,
            width: '185px',
            sortable: true
        },

        {
            name: 'Qty. in Cases',
            selector: row => row.quantity,
            cell: row => row.quantity,
            width: '150px',
            sortable: true
        },

        {
            name: 'Order Date',
            selector: row => (row.orderDate) ? moment(row.orderDate, 'YYYY-MM-DD HH:mm A') : 0,
            cell: row => row.orderDate ? moment(row.orderDate, 'YYYY-MM-DD hh:mm A').format('MM-DD-YYYY hh:mm A') : "-",
            width: '200px',
            sortable: true,
        },

        {
            name: 'Total Amount',
            selector: row => parseFloat(row.amount),
            cell: row => "$" + parseFloat(row.amount).toFixed(2),
            width: '150px',
            sortable: true,
        },

         {
            name: 'Brewer Pymt Amount',
            selector: row => row.brewerPaymentAmount,
             cell: row => "$" + row.brewerPaymentAmount,
            width: '200px',
            sortable: true
        },

         {
            name: 'Delivery Fee',
            selector: row => row.deliveryFee,
            cell: row => "$" + row.deliveryFee,
            width: '135px',
            sortable: true
        },

         {
            name: 'Bevport Net Amount',
            selector: row => row.bevportNetAmount,
             cell: row => "$" + row.bevportNetAmount,
            width: '190px',
            sortable: true
        },

        {
            name: 'Payment Method',
            selector: row => row.buyerPaymentType,
            cell: row => <span style={{ "color": "#00aeef" }}>{(row.buyerPaymentType == 1) ? "Fintech" : (row.buyerPaymentType == 2) ? "Check(COD)" : ""}</span>,
            width: '200px',
            sortable: true
        }
       
  
    ];


    const [data, setData] = useState([]);
    const [dataDetails, setDataDetails] = useState([]);


    useEffect(() => {
        getOrderHistory()

    }, [])

 
    const getOrderHistory = async () => {
        await axios.post(API_URL + "GetInvoiceData?code=" + API_KEY,{}, config).then((res) => {

            if (res.data.responseCode == "200") {
                let data = res.data.responseData
               const filterData = data.filter(item => item.status == 7)
                setData(filterData)

            } else {
                setData([])
            }
            setSubmitLoader(false);
        }).catch(function (error) {
            setSubmitLoader(false);
        })
    }




    const tableData = {
        columns,
        data
    };


    const onRowClicked = (data) => {
      // setShow(true);// has to be set before loading data into modal window
       // getOrderDetails(data.orderID, data.orderDateVal);

    };

    


    const ExpandedComponent = ({ data }) => {

      //  alert(data?.invoiceDetailsData[0].productName);

        // getOrderDetails(data.orderID, data.orderDateVal);

        //<div className='expandable-tablerow inventorytbds'>
        //    <div className='expandable col one'><strong>History</strong></div>
        //</div>

        return (<div className="expandable-tablerow">
            <div className="expandable col twelve">

                {/*<DataTableExtensions {...tableData2} export={true} print={false} filterPlaceholder="Search Invoices">*/}
                <DataTable
                   
                    columns={columnsDetails}
                    data={data?.invoiceDetailsData}
                    noDataComponent={<span className='norecordFound'>No record found!</span>}
                    noTableHead={true}

                    />
            {/*    </DataTableExtensions>*/}


                
            </div>

        </div>)


   
    };

 
    return (
        <>

            <div className="mainAdminWrapper">
                <Sidebar collapse={toggle} />
                <div className={!toggle?(submitLoader?"wholeCompoundRight vissibleHide":"wholeCompoundRight"):"wholeCompoundRight fullwidth"}>
                {submitLoader ? <Loader /> : ""}
                    <Header collapseSidebar={collapseSidebar} />
                    <div className="rightContetBlock">
                        <div className="headingTop">
                            <h1>Invoices</h1>
                        </div>
                        <div className="blank150"></div>
                        <div className="fixtopContentBg">
                            <div className="card-Content">
                                <div className="Content-body dataTableSearchBox invoiceTableRow">
                                    <DataTableExtensions {...tableData} export={false} print={false} filterPlaceholder="Search Invoices">
                                    <DataTable
                                            columns={columns}
                                            data={data}
                                            defaultSortAsc={false}
                                            defaultSortFieldId={5}
                                            noDataComponent={<span className='norecordFound'>No record found!</span>}
                                            onRowClicked={onRowClicked}


                                            expandableRows={true}
                                            expandableRowsComponent={ExpandedComponent}
                                            expandableRowExpanded={() => false}
                                           
                                    />
                                    </DataTableExtensions>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}