import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "./../front/Loader";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from 'moment';
import axios from 'axios';
import { API_URL, API_KEY, API_TOKEN } from './../../config/constant';
const config = {
	headers: { Authorization: `Bearer ${API_TOKEN}` }
};

toast.configure()
export default function BuyerList() {
	document.title = "BevPort - Buyer List"
	const [toggle, setToggle] = useState(window.innerWidth <= 768 ? true : false)
	const [submitLoader, setSubmitLoader] = useState(true)
	const [loader, setLoader] = useState(false)

	const collapseSidebar = () => {
		setToggle(!toggle)
	}

	const handleStatus = async (status, id) => {
		let formData = {
			"ID": id,
			"Status": status
		}
		setLoader(true);
		await axios.post(API_URL + "ChangeUserStatus?code=" + API_KEY, formData, config).then((res) => {
			if (res.data.responseCode == "200") {
				toast.success(res.data.responseMessage, {
					autoClose: 3000, //3 seconds
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,

				});
				getBuyerList()
				setLoader(false);
			} else {
				toast.error(res.data.responseMessage)
			}
		}).catch(function (error) {

		})
	}
	const alertFunction = () => {
		toast.error("Please activate brand before adding products")
	}
	const columns = [
		{
			name: 'Name',
			selector: row => (row.firstName + " " + row.lastName).toLowerCase(),
			cell: row => row.firstName + " " + row.lastName,
			sortable: true,

		},
		{
			name: 'E-mail',
			selector: row => row.emailID,
			cell: row => row.emailID.toString(),
			sortable: true,
			width: '250px',

		},
		{
			name: 'Phone No.',
			selector: row => row.businessPhone,
			cell: row => row.businessPhone.toString(),
			sortable: true,

		},
		{
			name: 'Business Name',
			selector: row => (row.businessName).toLowerCase(),
			cell: row => row.businessName.toString(),
			sortable: true,

		},

		{
			name: 'Address',
			selector: row => row.streetAddress + ", " + row.city + ", " + row.stateName + ", " + row.zipCode + ", " + "USA",
			cell: row => row.streetAddress + ", " + row.city + ", " + row.stateName + ", " + row.zipCode + ", " + "USA",
			sortable: true,
			wrap: true

		},

		{
			name: 'Status',
			selector: row => row.status,
			cell: row => (row.status == 10) ? <span style={{ "color": "#00aeef" }}>Active</span> : <span style={{ "color": "#00aeef" }}>Inactive</span>,
			sortable: true


		},
		{
			name: 'Signed On',
			selector: row => (row.loggedInDateTime) ? moment(row.loggedInDateTime, 'YYYY-MM-DD HH:mm A') : 0,
			cell: row => row.loggedInDateTime ? moment(row.loggedInDateTime, 'YYYY-MM-DD hh:mm A').format('MM-DD-YYYY hh:mm A') : "-",
			width: '200px',
			sortable: true,
		},
		{
			name: 'Action',
			width: '250px',
			export: false,
			selector: row => <>
				<Link className="btn btn-primary" to={"/admin/edit-buyers/" + row.id}>Edit</Link>&nbsp;
				<Link className="btn btn-secondary" to="#" onClick={() => handleStatus((row.status == "10") ? "90" : "10", row.id)}>{(row.status == "10") ? "DEACTIVATE" : "Activate"}</Link> </>,
		}
	];
	const [data, setData] = useState([])
	useEffect(() => {

		getBuyerList()
	}, [])
	const getBuyerList = async (userID) => {
		await axios.post(API_URL + "GetAllBuyersList?code=" + API_KEY, {}, config).then((res) => {
			if (res.data.responseCode == "200") {
				setData(res.data.responseData)
				console.log("data", res.data.responseData);

			} else {
				setData([])

			}
			setSubmitLoader(false);
		}).catch(function (error) {
			setSubmitLoader(false);
		})
	}
	const tableData = {
		columns,
		data
	};
	return (
		<>
			{submitLoader ? <Loader /> : ""}
			<div className="mainAdminWrapper">
				<Sidebar collapse={toggle} />
				<div className={!toggle ? (submitLoader ? "wholeCompoundRight vissibleHide" : "wholeCompoundRight") : "wholeCompoundRight fullwidth"}>
					{submitLoader ? <Loader /> : ""}
					{loader ? <Loader /> : ""}
					<Header collapseSidebar={collapseSidebar} />
					<div className="rightContetBlock">
						<div className="headingTop">
							<h1>Buyer List</h1>
						</div>
						<div className="blank150"></div>

						<div className="fixtopContentBg">
							<div className="card-Content">
								<div className="editUserProfile mb-15">
									<Link className="btn btn-primary save-button " to={"/admin/add-buyer"}>
										Add Buyer
									</Link>
								</div>
								<div className='tbaShowcase'>
									<div className="Content-body dataTableSearchBox">
										<DataTableExtensions {...tableData} exportHeaders export={true} print={false} filterPlaceholder="Search Buyers">
											<DataTable
												columns={columns}
												data={data}
												noDataComponent={<span className='norecordFound'>No record found!</span>}
												//defaultSortFieldId={1}
											/>
										</DataTableExtensions>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</div>
			</div>
		</>
	)
}